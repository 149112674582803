<template>
  <Layout
    :cls="[
      opt.userAns ? 'selected' : '',
      opt.correctAns ? 'correctAns' : '',
      isWrong ? 'isWrong' : '',
    ]"
    :opt="opt"
  >
  </Layout>
</template>

<script>
import _ from 'lodash';
import { computed } from 'vue';
import Layout from './_OptLayout.vue';
export default {
  components: { Layout },
  props: {
    opt: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const isCorrect = computed(() => {
      return props.opt.userAns && props.opt.correctAns;
    });

    const isWrong = computed(() => {
      return props.opt.status === 'wrong';
    });

    return { isCorrect, isWrong };
  },
};
</script>
