<template>
  <div>
    <div class="flex items-center py-4">
      <ElAvatar
        :src="user.imgUrl"
        size="small"
        class="flex-shrink-0"
      ></ElAvatar>
      <span class="ml-2 flex-shrink-0 text-sm">{{ user.name }}</span>
      <div class="flex items-center flex-grow space-x-4">
        <ElInput
          v-model="text"
          placeholder="留個言吧"
          class="ml-4"
          size="large"
          @keyup.enter="submit"
        ></ElInput>
        <El-Button :loading="loading" @click="submit">送出</El-Button>
      </div>
    </div>

    <div class="border-t py-4 space-y-4">
      <div v-for="row in rows" :key="row.id" class="flex space-x-3">
        <ElAvatar :src="row.imgUrl"></ElAvatar>

        <div class="rounded bg-primary-light py-4 px-6 w-full">
          <div class="">{{ row.userName || row.name }}</div>
          <div class="mt-2 break-words">
            {{ row.comment }}
          </div>
          <div class="text-xs">
            {{ row.date }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="rowsData.total > limit"
      class="py-4 border-t flex justify-center"
    >
      <el-button type="text" size="default" @click="toggle"
        >更多留言 +</el-button
      >
    </div>
  </div>
</template>

<script>
import { ElAvatar, ElInput } from 'element-plus';
import { inject, reactive, toRefs, ref, onMounted, computed } from 'vue';
import Comment from '@/api/Comment';
import _ from 'lodash';
export default {
  components: {
    ElAvatar,
    ElInput,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const limit = 5;
    const { id } = toRefs(props);
    const { user } = inject('auth');
    const loading = ref(false);

    const show = ref(false);
    const rowsData = reactive({
      total: 0,
      rows: [],
    });

    const _rows = computed(() => {
      const data = _.cloneDeep(rowsData.rows);
      return data.splice(0, show.value ? 999 : limit);
    });
    const text = ref('');

    const fetcher = () => {
      loading.value = true;
      Comment.list({ id: id.value })
        .then((res) => {
          if (res.code === 1) {
            rowsData.rows.push(...res.data.rows);
            rowsData.total = res.data.rows.length;
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const submit = () => {
      loading.value = true;
      Comment.add({ id: id.value, comment: text.value })
        .then((res) => {
          if (res.code === 1) {
            rowsData.rows.unshift({
              id: _.uniqueId(),
              comment: text.value,
              imgUrl: res.data.imgUrl,
              userName: user.name,
              date: res.data.date,
            });
            text.value = '';
            rowsData.total += 1;
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const toggle = () => {
      show.value = !show.value;
    };

    onMounted(() => {
      fetcher();
    });
    return {
      rows: _rows,
      rowsData,
      text,
      submit,
      user,
      limit,
      toggle,
      loading,
    };
  },
};
</script>
