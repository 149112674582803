<template>
  <div class="text-sm">
    <span class="mr-4 text-gray py-1">補充說明: </span>
    {{ row }}
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: String,
      default: '目前沒有資料',
    },
  },
  setup() {
    return {};
  },
};
</script>
