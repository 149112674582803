<template>
  <!-- 這個"quest" class 不能改調 -->
  <div
    class="quest rounded bg-white shadow border border-gray-300 p-4"
    :class="getSty(row.groupPos)"
  >
    <div class="border-gray-300 rounded border">
      <Titles
        :q-type="row.qType"
        :no="row.no"
        :text="row.text"
        :group-main="row.groupMain"
      />

      <div class="divide-y">
        <slot name="answerBlock"></slot>
      </div>

      <slot name="infoBlock"></slot>
    </div>
    <slot name="appendBlock"></slot>
  </div>
</template>

<script>
import { ref } from 'vue';
import Titles from './_Titles.vue';

export default {
  components: { Titles },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const getSty = (pos) => {
      return pos === 'start'
        ? '!rounded-b-none !border-b-0 '
        : pos === 'mid'
        ? 'rounded-none !border-t-0 !border-b-0 !-mt-2'
        : pos === 'end'
        ? 'rounded-t-none !border-t-0 !-mt-2'
        : '';
    };
    const showgroupMain = () => {
      console.log('aaaa');
    };

    const visible = ref(false);
    return {
      getSty,
      visible,
      showgroupMain,
    };
  },
};
</script>
