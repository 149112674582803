<template>
  <Layout :row="row">
    <template #answerBlock>
      <div
        v-if="row.qType?.id > 3 && row.userResult"
        class="p-4 whitespace-pre-wrap"
        v-html="row.userResult"
      ></div>
      <div v-else-if="row.qType?.id <= 3">
        <Opt v-for="option in row.option" :key="option.id" :opt="option" />
      </div>
    </template>
    <template #infoBlock>
      <!-- info bar -->
      <div
        class="bg-green-light p-4 flex flex-col justify-between border-t md:flex-col"
      >
        <div class="flex md:flex-col">
          <!-- left wrap -->
          <div class="flex-shrink-0">
            <div class="flex items-center">
              <div class="text-lg">正確答案:</div>
              <div class="font-bold text-3xl text-primary ml-2">
                {{ row.ansStatus.correctAns }}
              </div>
              <div
                v-if="row.ansStatus.status !== ''"
                class="py-1 bg-white rounded px-3 font-bold ml-4 border-2"
                :class="
                  row.ansStatus.status == 'correct'
                    ? ' text-info-4 border-info-4'
                    : ' text-danger  border-danger'
                "
              >
                {{ getAnsStatus(row.ansStatus.status) }}
                <!-- 作答正確 -->
              </div>
            </div>

            <div class="flex mt-4 items-center">
              <div
                v-if="row.avgCorrectRate !== ''"
                class="rounded bg-info-4 text-white px-3 py-1 flex-shrink-0"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                <span class="ml-2">{{ row.avgCorrectRate }}</span>
              </div>

              <div
                v-if="row.avgAnsRate && Object.keys(row.avgAnsRate).length > 0"
                class="text-gray ml-4 space-x-4"
              >
                <span
                  v-for="(ansRate, key) in row.avgAnsRate"
                  :key="key"
                  class="whitespace-nowrap"
                  >{{ key }} {{ ansRate }}</span
                >
              </div>
            </div>
            <p class="mt-2 text-sm text-gray-400">(請重整畫面獲得最新資訊)</p>
          </div>

          <!-- right wrap -->
          <div class="ml-12 leading-relaxed md:ml-0 md:mt-6">
            <div class="text-primary mb-1">{{ row.subject.name }}</div>
            <div class="text-gray line-clamp-1" :title="row.title.name">
              {{ row.title.name }}
            </div>
            <div
              class="text-gray line-clamp-1"
              :title="getJoinString(row.subTitle, 'name')"
            >
              {{ getJoinString(row.subTitle, 'name') }}
            </div>
            <div class="text-gray line-clamp-1" :title="row.exam.name">
              {{ row.exam.name }}
            </div>

            <div class="text-gray">
              <span> 試題編號 : {{ row.id }}</span>
              <span class="ml-4">試卷編號 : {{ row.exam.id }}</span>
            </div>
          </div>
        </div>

        <!-- action bar -->
        <div
          class="flex justify-end mt-4 text-3xl text-gray space-x-6 md:justify-end md:mt-4"
        >
          <el-tooltip effect="dark" content="聯絡我們" placement="top">
            <div class="cursor-pointer" @click="dialog.toggle({ show: true })">
              <font-awesome-icon
                :icon="['far', 'envelope']"
                class="cursor-pointer"
              />
            </div>
          </el-tooltip>

          <El-Dialog v-model="dialog.state.show" destroy-on-close>
            <div class="p-8">
              <ContactForm
                :q-id="row.id"
                @submitted="dialog.toggle({ show: false })"
              />
            </div>
          </El-Dialog>

          <el-tooltip
            effect="dark"
            :content="isSaved ? '取消加入收藏題庫' : '加入收藏題庫'"
            placement="top"
          >
            <div
              class="ml-4 cursor-pointer"
              @click="handleUpdateSaved(!isSaved)"
            >
              <font-awesome-icon
                :icon="isSaved ? ['fa', 'bookmark'] : ['far', 'bookmark']"
                class="text-3xl text-gray duration-300"
                :class="{ 'text-yellow': isSaved }"
              />
            </div>
          </el-tooltip>
        </div>
      </div>
    </template>

    <template #appendBlock>
      <!-- explanation -->
      <div>
        <div
          class="bg-[#EBF5F8] mt-4 text-2xl text-primary h-[60px] flex items-center justify-center cursor-pointer"
          @click="toggleExpand"
        >
          試題解析
          <div class="ml-2 text-lg">
            <font-awesome-icon
              :icon="!showExpand ? ['fas', 'plus'] : ['fas', 'minus']"
            />
          </div>
        </div>
        <transition name="fade">
          <div v-if="showExpand" class="">
            <div class="p-4 border-dashed border-b">
              <div
                class="md:hidden pointer-events-none"
                v-html="row.analysis.web"
              ></div>
              <div
                class="hidden md:block pointer-events-none"
                v-html="row.analysis.mobile"
              ></div>

              <Extra
                v-if="row.extraInfo"
                class="mt-4"
                :row="row.extraInfo"
              ></Extra>
              <Scopes v-if="row.scope" class="mt-4" :rows="row.scope"></Scopes>
              <Attrs
                v-if="row.attribute"
                class="mt-4"
                :rows="row.attribute"
              ></Attrs>
              <Keywords
                v-if="row.index"
                class="mt-4"
                :rows="row.index"
              ></Keywords>

              <div v-if="!isReadonly" class="mt-4 text-gray">
                <Memo
                  :id="row.id"
                  :model-value="row.memo"
                  @update:modelValue="handleUpdateMemo"
                ></Memo>
              </div>
            </div>
            <!-- action bar -->
            <div
              class="flex justify-between items-center p-4 md:flex-col md:items-start"
            >
              <div class="flex space-x-4 text-gray flex-shrink-0">
                <div>
                  <font-awesome-icon :icon="['fas', 'bookmark']" class="mr-2" />
                  {{ row.saveCnt }}
                </div>

                <div v-if="!(version < 1)">
                  <font-awesome-icon
                    :icon="['fas', 'comment-alt']"
                    class="mr-2"
                  />25
                </div>
              </div>

              <div v-if="!isReadonly" class="flex items-center">
                <Conf
                  :id="row.id"
                  :model-value="row.conf"
                  @update:modelValue="handleUpdateConf"
                ></Conf>
              </div>
            </div>

            <!-- 留言區 -->
            <MsgArea
              v-if="!isReadonly && !(version < 1)"
              :id="row.id"
              class="px-4 border-dashed border-t"
            ></MsgArea>
          </div>
        </transition>
      </div>
    </template>
  </Layout>
</template>

<script>
import { inject, computed } from 'vue';
import Opt from './ans/OptResult.vue';
import { getJoinString } from '@/utils';
import { ref, toRefs } from 'vue';
import Conf from '@/modules/quest/conf/Index';
import MsgArea from './msgArea/Index';
import Layout from './_Layout.vue';
import Memo from './_Memo.vue';

import Extra from './_Extra.vue';
import { ElDialog, ElTooltip } from 'element-plus';
import Keywords from './_Keywords.vue';
import Scopes from './_Scopes.vue';
import Attrs from './_Attrs.vue';
import useDialog from '@/hook/useDialog';
import ContactForm from '@/modules/contact/Form.vue';
// 放config
const ANS_STATUS = {
  none: '未作答',
  correct: '作答正確',
  wrong: '作答錯誤',
};

export default {
  components: {
    ElTooltip,
    Layout,
    Opt,
    Conf,
    MsgArea,
    Memo,
    Keywords,
    Scopes,
    Attrs,
    Extra,
    ElDialog,
    ContactForm,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { row } = toRefs(props);
    const version = inject('version');

    const getAnsStatus = (status) => {
      return ANS_STATUS[status] ? ANS_STATUS[status] : ANS_STATUS['none'];
    };

    const showExpand = ref(false);
    const permission = inject('permission');
    const toggleExpand = async () => {
      if (!(await permission.dialog(row.value.member_status))) return;
      showExpand.value = !showExpand.value;
    };
    const { saveds, updateSaved, updateConf } = inject('actions');

    const handleUpdateConf = (confId) => {
      updateConf({ id: row.value.id, conf: confId });
    };

    const isSaved = computed(() => {
      if (!saveds) return false;
      return saveds.indexOf(row.value.id) > -1;
    });

    const handleUpdateSaved = (saved = true) => {
      updateSaved({ id: row.value.id, saved });
    };

    const handleUpdateMemo = (text) => {
      // updateMemo({ id: row.value.id, memo: text });
    };
    const dialog = useDialog();
    return {
      version,
      getAnsStatus,
      showExpand,
      toggleExpand,
      getJoinString,
      handleUpdateConf,
      handleUpdateMemo,
      handleUpdateSaved,
      isSaved,
      dialog,
    };
  },
};
</script>
