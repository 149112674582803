<template>
  <div
    class="flex items-center text-xl p-4 border-b border-black md:flex-col md:items-start"
  >
    <div class="flex items-center md:w-full">
      <div
        class="text-white rounded py-2 px-4 whitespace-nowrap"
        :style="{
          backgroundColor: getTestTypeColor(qType.id),
        }"
      >
        {{ qType.name }}
      </div>
      <div class="ml-auto">
        <GroupMain v-if="isMobile && groupMain" :text="groupMain"></GroupMain>
      </div>
    </div>
    <div class="flex ml-4 md:mt-4 w-full">
      <div v-if="no">{{ no }}.</div>
      <div class="ml-2" v-html="text"></div>
    </div>
    <GroupMain v-if="!isMobile && groupMain" :text="groupMain"></GroupMain>
  </div>
</template>
<script setup>
import { getTestTypeColor } from '@/utils';
import { defineProps, inject, ref } from 'vue';
import GroupMain from './_GroupMain.vue';
defineProps({
  qType: {
    type: Object,
    default: () => {},
  },
  no: {
    type: Number,
    default: 0,
  },
  text: {
    type: String,
    default: '',
  },
  groupMain: {
    type: String,
    default: '',
  },
});

const isMobile = inject('isMobile');
</script>
