<template>
  <div v-if="rows.length" class="flex text-sm">
    <span class="mr-4 text-gray py-1 flex-shrink-0">章節: </span>
    <div class="flex flex-wrap">
      <span v-for="row in rows" :key="row" class="py-1 px-2 mr-2">
        {{ row }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
};
</script>
