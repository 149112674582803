import Axios from './http';
const prefix = '/consumer/v1';

export default class Comment {
  static async add(params) {
    return Axios(prefix + '/question/comment/add', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async list(params) {
    return Axios(prefix + '/question/comment/list', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
}
