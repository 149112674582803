<template>
  <el-select
    v-model="model"
    class="m-2 w-[13rem]"
    placeholder="信心指數"
    :suffix-icon="null"
    value-key="id"
    size="large"
  >
    <template #prefix>
      <img class="w-6 h-6" :src="active.icon" alt="" />
    </template>

    <el-option
      v-for="opt in opts"
      :key="opt.id"
      :value="opt.id"
      :label="opt.name"
    >
      <div class="flex items-center">
        <img class="w-6 h-6" :src="opt.icon" alt="" />
        <span class="ml-2">
          {{ opt.name }}
        </span>
      </div>
    </el-option>
  </el-select>
</template>
<script>
import { reactive, toRefs, computed, inject } from 'vue';
import { ElSelect, ElOption } from 'element-plus';

import Conf from '@/api/Conf';

import { ElMessage } from 'element-plus';

export default {
  components: { ElSelect, ElOption },
  props: {
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { confOpts } = inject('store');
    const { modelValue, id } = toRefs(props);

    const model = computed({
      set(val) {
        if (val == model.value) return;
        Conf.save({ id: id.value, conf: val }).then((res) => {
          if (res.code !== 1) {
            ElMessage({ message: '更新失敗' });
          } else {
            emit('update:modelValue', val);
          }
        });
      },
      get() {
        return modelValue.value;
      },
    });
    const active = computed(() => {
      return opts.find((x) => x.id == model.value);
    });

    const opts = reactive([...confOpts]);

    return {
      model,
      opts,
      active,
    };
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-input--prefix) {
  .el-input__inner {
    padding-left: 3rem !important;
    padding-right: 1rem !important;
  }
}
</style>
