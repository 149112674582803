<template>
  <div v-if="rows.length" class="flex">
    <span class="mr-4 text-sm text-gray py-1 flex-shrink-0">關鍵字: </span>
    <div class="flex flex-wrap">
      <span
        v-for="row in rows"
        :key="row.id"
        class="py-1 px-2 bg-info text-white rounded text-sm mr-2 mb-2 whitespace-nowrap"
        >{{ row.name }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
};
</script>
