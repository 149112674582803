<template>
  <div class="flex space-x-4">
    <div class="relative flex-grow">
      <el-input
        v-model="text"
        placeholder="請在此添加註解"
        :rows="3"
        type="textarea"
      >
      </el-input>
      <a
        v-show="text"
        href="#"
        class="absolute right-2 top-2 rounded-full w-6 h-6 flex justify-center items-center border border-gray-300"
        @click.prevent="text = ''"
      >
        <font-awesome-icon :icon="['fas', 'times']" />
      </a>
    </div>
    <El-Button :loading="loading" @click="update">添加註解</El-Button>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue';
import { ElInput, ElButton } from 'element-plus';
import { useVModel } from '@vueuse/core';
import Tester from '@/api/Tester.js';
import { ElMessage } from 'element-plus';
export default {
  components: { ElInput, ElButton },
  props: {
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { id } = toRefs(props);

    const model = useVModel(props, 'modelValue', emit);
    const loading = ref(false);
    const text = ref(model.value);
    const update = () => {
      loading.value = true;
      Tester.memo({ id: id.value, memo: text.value })
        .then((res) => {
          if (res.code !== 1) {
            ElMessage({ message: '失敗' });
          } else {
            ElMessage({ message: '成功更新', type: 'success' });
            model.value = text.value;
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };
    return { loading, text, update };
  },
};
</script>
