<template>
  <div class="flex items-center p-4 text-xl md:items-start" :class="[...cls]">
    <div
      class="w-16 h-16 pb-1 mr-2 flex justify-center items-center rounded-full duration-300 font-bold text-3xl border flex-shrink-0 option-index"
    >
      {{ opt.name }}
    </div>
    <!--   :class="[selected ? 'text-black' : 'text-gray']" -->
    <div
      :class="!$slots.append ? 'flex-grow w-0' : ''"
      class="option-content md:mt-3"
      v-html="opt.content"
    ></div>
    <div class="ml-6 text-3xl option-append">
      <slot name="append"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cls: {
      type: Array,
      default: () => [],
    },
    opt: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="sass" scoped>

.selected
  .option-index
    @apply text-white bg-primary

// .correctAns
//   .option-content
//     @apply text-info-4

.isWrong
  .option-content
    @apply text-danger
    :deep(img)
      @apply border-2 rounded px-2 border-danger
</style>
